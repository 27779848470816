import { Component, NgZone } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { register } from "swiper/element/bundle";
import { App, URLOpenListenerEvent } from "@capacitor/app";
register();

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { Router } from "@angular/router";
import { AdsService } from "./core/services/ads.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private db: AngularFireDatabase,
    private auth: AngularFireAuth,
    private statusBar: StatusBar,
    private router: Router,
    private zone: NgZone,
    private ads: AdsService
  ) {
    this.initializeApp();
    ads.loadAds();
  }

  initializeApp() {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = "tuguru.app";

        const pathArray = event.url.split(domain);
        // The pathArray is now like ['https://devdactic.com', '/details/42']

        // Get the last element with pop()
        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
    this.platform.ready().then(() => {
      // this.statusBar..setBackgroundColor({ color: "#ffffff" });
      // StatusBar.setStyle({ style: StatusBarStyle.Light });
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString("#ffffff");
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.auth.authState.subscribe((user: any) => {
        if (user != null) {
          // Push Notifications
          // Request permission to use push notifications
          // iOS will prompt user and return if they granted permission or not
          // Android will just grant without prompting
          PushNotifications.requestPermissions().then((result) => {
            console.log("response del result - " + JSON.stringify(result));
            if (result.receive === "granted") {
              // Register with Apple / Google to receive push via APNS/FCM
              PushNotifications.register();
            } else {
              console.log("ocurrio un error registrando el permiso");
              // Show some error
            }
          });
          PushNotifications.addListener("registration", (token: Token) => {
            // alert('Push registration success, token: ' + token.value);
            console.log("response del token - " + JSON.stringify(token));
            localStorage.setItem("deviceId", token.value);
            this.db.database
              .ref("clients/" + user.uid)
              .once("value", (h: any) => {
                console.log("====================token id", h);
                if (h.val() != null) {
                  // if (h.val().status == "approved") {
                  this.db.database
                    .ref("clients/" + user.uid + "/deviceId")
                    .set(token.value);
                  // }
                }
              })
              .catch((error) => {
                this.db.database.ref("errors").push({
                  notify: true,
                  app: "client",
                  location: "app-component",
                  uid: localStorage.getItem("uid"),
                  funtion: "initializeApp - uploadToken",
                  response: error,
                });
              });
          });
          PushNotifications.addListener("registrationError", (error: any) => {
            console.log("error en el registration - " + JSON.stringify(error));
            alert("Error on registration: " + JSON.stringify(error));
          });
          PushNotifications.addListener(
            "pushNotificationReceived",
            (notification: PushNotificationSchema) => {
              alert("Push received: " + JSON.stringify(notification));
              console.log(
                "===============notification================",
                JSON.stringify(notification)
              );
            }
          );
          PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (notification: ActionPerformed) => {
              this.router.navigate(
                [
                  "/chat",
                  notification.notification.data.route,
                  notification.notification.data.guru,
                ],
                { replaceUrl: true }
              );
            }
          );
        }
      });
    });
  }
}
