import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "client-tabs",
    loadChildren: () =>
      import("./client-tabs/client-tabs.module").then(
        (m) => m.ClientTabsPageModule
      ),
  },
  {
    path: "categories",
    loadChildren: () =>
      import("./categories/categories.module").then(
        (m) => m.CategoriesPageModule
      ),
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./notifications/notifications.module").then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./profile/profile.module").then((m) => m.ProfilePageModule),
  },
  {
    path: "request-information",
    loadChildren: () =>
      import("./request-information/request-information.module").then(
        (m) => m.RequestInformationPageModule
      ),
  },
  {
    path: "request-date-time",
    loadChildren: () =>
      import("./request-date-time/request-date-time.module").then(
        (m) => m.RequestDateTimePageModule
      ),
  },
  {
    path: "guru-list",
    loadChildren: () =>
      import("./guru-list/guru-list.module").then((m) => m.GuruListPageModule),
  },
  {
    path: "guru-details/:id",
    loadChildren: () =>
      import("./guru-details/guru-details.module").then(
        (m) => m.GuruDetailsPageModule
      ),
  },
  {
    path: "resume-services",
    loadChildren: () =>
      import("./resume-services/resume-services.module").then(
        (m) => m.ResumeServicesPageModule
      ),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("./payment/payment.module").then((m) => m.PaymentPageModule),
  },
  {
    path: "add-card",
    loadChildren: () =>
      import("./add-card/add-card.module").then((m) => m.AddCardPageModule),
  },
  {
    path: "category-modal",
    loadChildren: () =>
      import("./category-modal/category-modal.module").then(
        (m) => m.CategoryModalPageModule
      ),
  },
  {
    path: "location-modal",
    loadChildren: () =>
      import("./location-modal/location-modal.module").then(
        (m) => m.LocationModalPageModule
      ),
  },
  {
    path: "payment-modal",
    loadChildren: () =>
      import("./payment-modal/payment-modal.module").then(
        (m) => m.PaymentModalPageModule
      ),
  },
  {
    path: "notifiaction-details",
    loadChildren: () =>
      import("./notifiaction-details/notifiaction-details.module").then(
        (m) => m.NotifiactionDetailsPageModule
      ),
  },
  {
    path: "map-modal/:guruId",
    loadChildren: () =>
      import("./map-modal/map-modal.module").then((m) => m.MapModalPageModule),
  },
  {
    path: "paymemt-success",
    loadChildren: () =>
      import("./paymemt-success/paymemt-success.module").then(
        (m) => m.PaymemtSuccessPageModule
      ),
  },
  {
    path: "edition-modal",
    loadChildren: () =>
      import("./edition-modal/edition-modal.module").then(
        (m) => m.EditionModalPageModule
      ),
  },
  {
    path: "resume-payment-modal",
    loadChildren: () =>
      import("./resume-payment-modal/resume-payment-modal.module").then(
        (m) => m.ResumePaymentModalPageModule
      ),
  },
  {
    path: "directions-map/:id/:lat/:lng",
    loadChildren: () =>
      import("./directions-map/directions-map.module").then(
        (m) => m.DirectionsMapPageModule
      ),
  },
  {
    path: "modal-horario",
    loadChildren: () =>
      import("./modal-horario/modal-horario.module").then(
        (m) => m.ModalHorarioPageModule
      ),
  },
  {
    path: "conf",
    loadChildren: () =>
      import("./conf/conf.module").then((m) => m.ConfPageModule),
  },
  {
    path: "repeat-modal-time",
    loadChildren: () =>
      import("./repeat-modal-time/repeat-modal-time.module").then(
        (m) => m.RepeatModalTimePageModule
      ),
  },
  {
    path: "event-list/:from/:id",
    loadChildren: () =>
      import("./event-list/event-list.module").then(
        (m) => m.EventListPageModule
      ),
  },
  {
    path: "event-detail/:from/:id",
    loadChildren: () =>
      import("./event-detail/event-detail.module").then(
        (m) => m.EventDetailPageModule
      ),
  },
  {
    path: "atendde-confirm",
    loadChildren: () =>
      import("./atendde-confirm/atendde-confirm.module").then(
        (m) => m.AtenddeConfirmPageModule
      ),
  },
  {
    path: "waiting-modal",
    loadChildren: () =>
      import("./waiting-modal/waiting-modal.module").then(
        (m) => m.WaitingModalPageModule
      ),
  },
  {
    path: "event-menu",
    loadChildren: () =>
      import("./event-menu/event-menu.module").then(
        (m) => m.EventMenuPageModule
      ),
  },
  {
    path: "new-event",
    loadChildren: () =>
      import("./new-event/new-event.module").then((m) => m.NewEventPageModule),
  },
  {
    path: "event-map",
    loadChildren: () =>
      import("./event-map/event-map.module").then((m) => m.EventMapPageModule),
  },
  {
    path: "qr-code",
    loadChildren: () =>
      import("./qr-code/qr-code.module").then((m) => m.QrCodePageModule),
  },
  {
    path: "qr-scanner/:id",
    loadChildren: () =>
      import("./qr-scanner/qr-scanner.module").then(
        (m) => m.QrScannerPageModule
      ),
  },
  {
    path: "event-certified",
    loadChildren: () =>
      import("./event-certified/event-certified.module").then(
        (m) => m.EventCertifiedPageModule
      ),
  },
  {
    path: "trust",
    loadChildren: () =>
      import("./trust/trust.module").then((m) => m.TrustPageModule),
  },
  {
    path: "trust/:id",
    loadChildren: () =>
      import("./trust/trust.module").then((m) => m.TrustPageModule),
  },
  {
    path: "event-atenddi/:id",
    loadChildren: () =>
      import("./event-atenddi/event-atenddi.module").then(
        (m) => m.EventAtenddiPageModule
      ),
  },
  {
    path: "chat/:id/:guru",
    loadChildren: () =>
      import("./chat/chat.module").then((m) => m.ChatPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComponentsRoutingModule {}
