// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    projectId: 'tu-guru',
    appId: '1:269388067251:web:067582df1e3fa4bf72dbc6',
    databaseURL: 'https://tu-guru.firebaseio.com',
    storageBucket: 'tu-guru.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyBSMlswlvaAaTp2EPVT2m62nHEK71oKlmk',
    authDomain: 'tu-guru.firebaseapp.com',
    messagingSenderId: '269388067251',
    measurementId: 'G-H7B8940XWQ',
  },
  // stripePublicKey: "pk_test_QzcjsAG0ADm02szUk4jnn9XA00LGi6ixK2",
  stripePublicKey: "pk_live_2ot735zmrPXbbbiG8KWLemQk00yKfMTqCY",
  googleClientIDIos:
    "269388067251-pssjk3jjjphjhnfhu3v54f89eh2pf68g.apps.googleusercontent.com",
  mapAccessToken:
    "pk.eyJ1IjoicGl4bmFiaSIsImEiOiJjamhrdjg4eXMyeDk4M2RtN2twMWkyZWI3In0.u5lGsCbV-mc17DRcFviPHw",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
