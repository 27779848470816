import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";

@Injectable({
  providedIn: "root",
})
export class AdsService {
  ads = [];
  ads2 = [];
  notifications = [];
  home = [];
  guruList = [];
  constructor(private db: AngularFireDatabase) {}

  loadAds() {
    this.db
      .list("ads", (ref) => ref.orderByChild("status").equalTo("Activo"))
      .valueChanges()
      .subscribe(async (data: any) => {
        for (let k of data) {
          k.image = k.picture;
          k.thumbImage = k.picture;
          this.ads2.push(k);
        }
        await this.ads2.sort(() => (Math.random() > 0.5 ? 1 : -1));
        for (let i = 0; i < this.ads2.length; i++) {
          this.ads.push(this.ads2[i]);
        }

        this.buildAdsgroup();
      });
  }

  buildAdsgroup() {
    for (let i = 0; i < this.ads.length; i++) {
      if (
        this.ads[i].category == "notify" ||
        this.ads[i].category == "startAndNotify" ||
        this.ads[i].category == "notifyAndSearch" ||
        this.ads[i].category == "all"
      ) {
        this.notifications.push(this.ads[i]);
      }

      if (
        this.ads[i].category == "start" ||
        this.ads[i].category == "startAndNotify" ||
        this.ads[i].category == "startAndSearch" ||
        this.ads[i].category == "all"
      ) {
        this.home.push(this.ads[i]);
      }

      if (
        this.ads[i].category == "search" ||
        this.ads[i].category == "startAndSearch" ||
        this.ads[i].category == "notifyAndSearch" ||
        this.ads[i].category == "all"
      ) {
        this.guruList.push(this.ads[i]);
      }
    }
  }

  loadNotify() {
    return this.notifications;
  }

  loadHome() {
    return this.home;
  }

  loadGuruList() {
    return this.guruList;
  }
}
