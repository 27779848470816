import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import {
  AngularFireAuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
} from "@angular/fire/compat/auth-guard";

const redirectLoggedToHome = () => redirectLoggedInTo(["/client-tabs"]);

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
    ...canActivate(redirectLoggedToHome),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./register/register.module").then((m) => m.RegisterPageModule),
  },
  {
    path: "password-recovery",
    loadChildren: () =>
      import("./password-recovery/password-recovery.module").then(
        (m) => m.PasswordRecoveryPageModule
      ),
  },
  {
    path: "login-modal/:type",
    loadChildren: () =>
      import("./login-modal/login-modal.module").then(
        (m) => m.LoginModalPageModule
      ),
  },
  {
    path: "register-modal",
    loadChildren: () =>
      import("./register-modal/register-modal.module").then(
        (m) => m.RegisterModalPageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
